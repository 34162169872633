<template>
	<div>
		<div
			class=""
		>
			<ul>
				<li
					v-for="(card, index) in item_result.cartl_nft_purchase_list"
					:key="'card_' + index"
					class="main_card main_card_type4 inline-block box-staking"
				>
					<div class="position-relative">
						<img
							:src="$request.upload_url(card.cartl_nft_img_url)"
							class="width-100 radius-10"
						>
						<img
							:src="$request.upload_url(card.cartl_nft_catgy_img_url)"
							class="position-absolute width-badge"
							style="left: 10px; top: 10px"
						/>
					</div>
				</li>
			</ul>
		</div>

		<hr class="under-line mtb-30" />

		<div
			class="flex-row justify-space-between"
		>
			<div class="flex-1">
				<b class=" size-px-18">BUY</b>
				<strong class="col_red size-px-18"> {{ text_coin_quantity }} {{ item_card.sl_coin_token_name }}</strong>

				<div class="mt-20 ">
					<button
						class="btn-inline btn-primary-outline radius-20"
						@click="$router.push({ name: 'nft'})"
					>NFT 목록</button>
				</div>
			</div>

			<div class="flex-1 text-right">
				<p class="color-gray">NFT 컬랙션에서 구입하신 {{  item_card.nft_card_div_name }}를 확인하실 수 있습니다.</p>
				<div class="mt-20">
					<button class="btn-inline radius-20 btn_fill_blue" @click="$router.push({name: 'drops'})">확인</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia0431'
		, props: ['user', 'item_card', 'item_result']
		, data: function () {
			return {
				lb: 'down'
			}
		}
		, computed: {
			item_info: function(){

				let t = this.item_result

				if(t){

					if (!t?.cartl_nft_purchase_list) {
						let d = []
						d.push({
							cartl_nft_img_url: t?.sl_img_url
						})
						this.$set(t, 'cartl_nft_purchase_list', d)
					}
				}else{
					t = {

					}
				}

				return t
			}
			, text_coin_quantity: function(){
				let t = this.item_info.coin_token_quantity ? this.item_info.coin_token_quantity : this.item_info.tot_purchase_coin_token_quantity ? this.item_info.tot_purchase_coin_token_quantity: 0

				return t
			}
		}
		, methods: {
		}

		, created() {
		}
	}
</script>

<style>
	.down {
		bottom: 0;
		transition: all 1s
	}

	.up {
		bottom: -230px;
		transition: bottom 1s
	}

	.main_card_wrap::-webkit-scrollbar {
		width: 2px;

		background-color: #000;
	}
	.main_card_wrap::-webkit-scrollbar-thumb {
		background-color: #333;
	}

	.card-box {
		max-height: 880px;
		overflow-y: scroll;
		padding-bottom: 120px
	}

	.lb-wrap {
		position: fixed; left: 0; border: 1px solid rgba(255, 255, 255, 0.05); width: 100%; height: 264px; display: flex; flex-direction: row; justify-content: center; border-radius: 40px 40px 0 0; background-color: #181A39
	}
	.lb-trans {
		position: absolute; top: 14px; left: 50%; transform: translateX(-50%)
	}
	.lb-bg {
		width: 50px; height: 5px; background: #24264E; border-radius: 2.5px;
	}
	.lb-bottom-box {
		max-width: 320px; padding: 10px
	}
	.link i{
		background: transparent;
	}


	.box-staking {
		width: 210px;
	}

	.box-staking:not(:nth-child(4n)) {
		margin-right: 10px;
	}
</style>