<template>
	<div class="flex-side mr-30">
		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">DROPS</h3>
		</div>

		<div>
			<ul>
				<li
					v-for="(card_type, c_index) in card_type_list"
					:key="'card_type_' + c_index"
					class="under-line ptb-20 cursor-pointer"
					:class="{ on: item_card_type.code == card_type.code }"
					@click="toDrops(card_type)"
				>{{ card_type.name }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'drops_side'
		, props: []
		, data: function(){
			return {

				items_card_type: [
					{ code: 'NF00100005', name: 'UNION', is_use: false, to: { name: 'drops', params: { card_type: 'NF00100005'}}}
					, { code: 'NF00100007', name: 'MAFIA NFT', is_use: true, to: { name: 'drops', params: { card_type: 'NF00100007'}}}
				]
				, item_card_type: {
					code: 'NF00100007', name: 'MAFIA NFT'
				}
			}

		}
		, computed: {
			card_type_list: function(){
				let d = []
				this.items_card_type.filter((item) => {
					if(item.is_use){
						d.push(item)
					}
				})

				return d
			}
		}
		, methods: {
			toDrops: function(item){
				this.item_card_type = item
				this.$emit('click', item)
			}
		}
		, created() {
		}
	}
</script>